/**
 * 各画面で使用できる検索条件
 */
export type SearchParams = {
  mainField?: MainFieldType;
  fields?: string[];
  marking?: string;
  isFiltering?: boolean;
  containsAverage?: boolean;
  days?: DaysType[];
  dayType?: DayType;
  dateSuffixes?: number[];
  dates?: number[];
  makers?: string[];
  kiList?: string[];
  sisTypes?: string[];
  shuGroupIds?: string[];
  shuList?: string[];
  hallsForDonyuJokyo?: string[];
  isDonyuForDonyuJokyo?: boolean;
  areas?: string[];
  halls?: string[];
  startDate?: string;
  endDate?: string;
  startComparisonDate?: string;
  endComparisonDate?: string;
  excludeToday?: boolean;
  sort?: string;
  order?: OrderType;
  kadoFieldType?: KadoFieldType;
};

/**
 * SettingsOption内の検索条件項目一覧
 */
export type SearchCondition = {
  makers: Option[];
  kiList: Option[];
  kiTagList?: KiTagListOptions[];
  shuGroupList: ShuGroupList[];
  shuList: string[];
  areas: Area[];
  halls: Hall[];
  sisTypes: SisType[];
  latestBaseDateForDailySisData: string;
  latestBaseDateForWeeklySisData: string;
  latestBaseDateForMonthlySisData: string;
};

export type KiTagListOptions = {
  tagId: number;
  tagName: string;
  tagColor: string;
  viewOrder: number;
  kiList: string[];
};

export type SisType = {
  shuCode: string;
  viewOrder: number;
} & Option;

export const KADO_FIELD_TYPE = {
  VALUE: 'value',
  RATIO: 'ratio',
} as const;
export type KadoFieldType = typeof KADO_FIELD_TYPE[keyof typeof KADO_FIELD_TYPE];

export const MAIN_FIELD_TYPE = {
  KI_TUSHO_MEI: 'kiTushoMei',
  SHU: 'shu',
  TYPE: 'type',
  MAKER: 'maker',
  HL_MEI: 'hlMei',
  DAI_BAN: 'daiBan',
} as const;
export type MainFieldType = typeof MAIN_FIELD_TYPE[keyof typeof MAIN_FIELD_TYPE];

export const DAYS_TYPE = {
  MONDAY: '月',
  TUESDAY: '火',
  WEDNESDAY: '水',
  THURSDAY: '木',
  FRIDAY: '金',
  SATURDAY: '土',
  SUNDAY: '日',
} as const;
export type DaysType = typeof DAYS_TYPE[keyof typeof DAYS_TYPE];

export const DAY_TYPE = {
  ALL: 'all',
  WEEKDAY: 'weekday',
  HOLIDAY: 'holiday',
} as const;
export type DayType = typeof DAY_TYPE[keyof typeof DAY_TYPE];

export type OrderType = 'asc' | 'desc';

export type ColumnForDataTable = {
  name: string;
  isSisField?: boolean | null;
  code: string | null;
  type?: 'number' | 'numberWithPrefix' | 'string' | 'numberOrString' | null;
  backgroundColor?: string | null;
  color?: string | null;
  description?: string | null;
};

export type DataElementForTable = {
  value: string;
  color: string;
  backgroundColor?: string | null;
};

/**
 * @deprecated ColumnForDataTableを使用してください
 */
type DeprecatedColumnForDataTable = {
  name: string;
  code: string;
  type: string;
  backgroundColor: string | null;
  color: string | null;
  isSisField: boolean;
  //TODO:全ての画面でdescriptionが来ているか確認できたらオプショナルを外す
  description?: string | null;
};

/**
 * @deprecated ColumnForDataTableを使用してください
 */
export type Column = DeprecatedColumnForDataTable & {
  columns?: DeprecatedColumnForDataTable[];
};

export type Row<T = QueryParameter> = {
  queryParameter: T;
  data: Item[];
};

/**
 * @deprecated DataElementForTableを使用してください
 */
export type Item = {
  value: string; // TODO: nullableなので修正が必要
  color: string;
  backgroundColor: string | null;
};

export type QueryParameter = {
  name: string;
  value: string;
};

// FieldSettings
export type Option = {
  code: string;
  name: string;
  genres?: string[];
  isDefault?: boolean;
  isEnabled?: boolean;
  viewOrder?: number;
  unsupportedMainFields?: MainFieldType[];
  isSelectableForGraph?: boolean;
  supportsSis?: boolean;
  description?: string;
  isNew?: boolean;
};

export type ShuGroupList = {
  name: string;
  id: string;
  shuList: string[];
};

export type Area = {
  id: string;
  name: string;
  halls: string[];
};

export type Hall = {
  code: string;
  name: string;
  hallsOfMarketArea?: {
    code: string;
    name: string;
  }[];
};

export type GraphColumn = {
  name: string;
  type: string;
  digitsAfterDecimalPoint?: number | null;
};

export type GraphData = {
  columns: GraphColumn[];
  rows: (string | number)[][];
};

export type SummaryData<T = SummaryDataElement, U = SummaryDataElement> = {
  name?: string;
  original: T;
  comparison?: U | undefined;
};

export type SummaryDataElement = {
  value: string;
  unit: string;
  color: string;
};

export type SummaryDataElementWithRank = {
  rank: '1' | '2' | '3' | null;
} & SummaryDataElement;

export const hideColumns = (
  columns: Column[],
  rows: Row[],
  hiddenColumnCodes: string[]
) => {
  if (hiddenColumnCodes.length === 0) {
    return {
      columns,
      rows,
    };
  }

  const filteredRows = rows.map((row) => {
    if (columns.length !== row.data.length) {
      throw Error('カラムの数と行データの数が一致しません');
    }

    return {
      ...row,
      data: row.data.filter((_, i) => {
        const column = columns.at(i);
        return column ? !hiddenColumnCodes.includes(column.code) : true;
      }),
    };
  });
  const filteredColumns = columns.filter(
    ({ code }) => !hiddenColumnCodes.includes(code)
  );

  return { columns: filteredColumns, rows: filteredRows };
};

export type KiWithMakerListOptions = {
  code: string;
  name: string;
  maker: string;
};

export type LoadingState = 'prepare' | 'loading' | 'loaded' | 'failed';
