import { createSelector } from 'reselect';

import { DataDepreciationSearchParams } from '../../domain/depreciation/types';
import { KiTagListOptions, OrderType } from '../../domain/schemas';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_DEPRECIATION_SETTING = 'INIT_DEPRECIATION_SETTING' as const;
const SEARCH_DEPRECIATION_SETTING = 'SEARCH_DEPRECIATION_SETTING' as const;
const RENEW_DEPRECIATION_SETTING = 'RENEW_DEPRECIATION_SETTING' as const;
const SELECT_DEPRECIATION_FORM_CONDITION = 'SELECT_DEPRECIATION_FORM_CONDITION' as const;
const SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST = 'SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST' as const;
const SELECT_DEPRECIATION_CHECKED_KI_LIST = 'SELECT_DEPRECIATION_CHECKED_KI_LIST' as const;
const REMOVE_DEPRECIATION_CHECKED_KI_LIST = 'REMOVE_DEPRECIATION_CHECKED_KI_LIST' as const;
const TRIGGER_DEPRECIATION_SWAP_FIELDS = 'TRIGGER_DEPRECIATION_SWAP_FIELDS' as const;
const SELECT_DEPRECIATION_NAME_FILTER = 'SELECT_DEPRECIATION_NAME_FILTER' as const;
const TRIGGER_DEPRECIATION_SORT_AND_ORDER = 'TRIGGER_DEPRECIATION_SORT_AND_ORDER' as const;
const TRIGGER_DEPRECIATION_SORT = 'TRIGGER_DEPRECIATION_SORT' as const;
const TRIGGER_DATA_DEPRECIATION_2ROW = 'TRIGGER_DATA_DEPRECIATION_2ROW' as const;
const SELECT_DEPRECIATION_KI_TAG_LIST = 'SELECT_DEPRECIATION_KI_TAG_LIST' as const;
const SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL = 'SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL' as const;

export const DepreciationSettingActionTypes = {
  INIT_DEPRECIATION_SETTING,
  SEARCH_DEPRECIATION_SETTING,
  RENEW_DEPRECIATION_SETTING,
  SELECT_DEPRECIATION_FORM_CONDITION,
  SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST,
  SELECT_DEPRECIATION_CHECKED_KI_LIST,
  REMOVE_DEPRECIATION_CHECKED_KI_LIST,
  TRIGGER_DEPRECIATION_SWAP_FIELDS,
  SELECT_DEPRECIATION_NAME_FILTER,
  TRIGGER_DEPRECIATION_SORT_AND_ORDER,
  TRIGGER_DEPRECIATION_SORT,
  SELECT_DEPRECIATION_KI_TAG_LIST,
  SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initDepreciationSettingAction() {
  return {
    type: INIT_DEPRECIATION_SETTING,
  };
}

/**
 * 検索ボタンの押下
 */
function searchDepreciationSettingAction(
  searchParams: DataDepreciationSearchParams
) {
  return {
    type: SEARCH_DEPRECIATION_SETTING,
    payload: { searchParams },
  };
}

function renewDepreciationSettingAction() {
  return {
    type: RENEW_DEPRECIATION_SETTING,
  };
}

/**
 * FormConditionを変更
 */
function selectDepreciationFormCondition(
  formCondition: DepreciationSettingState['searchParams']
) {
  return {
    type: SELECT_DEPRECIATION_FORM_CONDITION,
    payload: { formCondition },
  };
}

/**
 * テーブルのチェックボックスで全選択した場合
 */
function selectDepreciationTableCheckedAllKiListAction(
  checkedKiList: { dataType: string; ki: string }[]
) {
  return {
    type: SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST,
    payload: checkedKiList,
  };
}

/*
 * テーブルのチェックボックスで選択した機種を追加
 */
function selectDepreciationCheckedKiListAction(checkedKiList: {
  dataType: string;
  ki: string;
}) {
  return {
    type: SELECT_DEPRECIATION_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/*
 * テーブルのチェックボックスで選択した機種を削除
 */
function removeDepreciationCheckedKiListAction(
  checkedKiList: {
    dataType: string;
    ki: string;
  }[]
) {
  return {
    type: REMOVE_DEPRECIATION_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/**
 * 表示項目の並び順を入替
 */
function triggerSwapFieldsAction(draggedId: string, droppedId: string) {
  return {
    type: TRIGGER_DEPRECIATION_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 機種名フィルタを変更
 */
function selectDepreciationNameFilterAction(name: string) {
  return {
    type: SELECT_DEPRECIATION_NAME_FILTER,
    payload: { name },
  };
}

/**
 * サブメニューからのソート変更
 */
function triggerDepreciationSortAndOrderAction(sort: string, order: OrderType) {
  return {
    type: TRIGGER_DEPRECIATION_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function triggerDepreciationSortAction(sort: string) {
  return {
    type: TRIGGER_DEPRECIATION_SORT,
    payload: { sort },
  };
}

/**
 * 店舗行を表示・非表示する
 */
function triggerDepreciation2RowAction() {
  return {
    type: TRIGGER_DATA_DEPRECIATION_2ROW,
    payload: {},
  };
}

/**
 * 機種タグ絞り込みを変更
 */
function selectDepreciationKiTagListAction(kiTagList: KiTagListOptions[]) {
  return {
    type: SELECT_DEPRECIATION_KI_TAG_LIST,
    payload: { kiTagList },
  };
}

/**
 * 機種行への機種タグ表示機能のフラグを変更
 */
function selectDepreciationShowKiTagLabelAction(showKiTagLabel: boolean) {
  return {
    type: SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL,
    payload: { showKiTagLabel },
  };
}

export const DepreciationSettingActionCreators = {
  initDepreciationSettingAction,
  searchDepreciationSettingAction,
  renewDepreciationSettingAction,
  selectDepreciationFormCondition,
  selectDepreciationTableCheckedAllKiListAction,
  selectDepreciationCheckedKiListAction,
  removeDepreciationCheckedKiListAction,
  triggerSwapFieldsAction,
  selectDepreciationNameFilterAction,
  triggerDepreciationSortAndOrderAction,
  triggerDepreciationSortAction,
  triggerDepreciation2RowAction,
  selectDepreciationKiTagListAction,
  selectDepreciationShowKiTagLabelAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitDepreciationSettingAction = ReturnType<
  typeof initDepreciationSettingAction
>;

type SearchDepreciationSettingAction = ReturnType<
  typeof searchDepreciationSettingAction
>;

type RenewDepreciationSettingAction = ReturnType<
  typeof renewDepreciationSettingAction
>;

type SelectDepreciationFormConditionAction = ReturnType<
  typeof selectDepreciationFormCondition
>;

type SelectDepreciationTableCheckedAllKiListAction = ReturnType<
  typeof selectDepreciationTableCheckedAllKiListAction
>;
type SelectDepreciationCheckedKiListAction = ReturnType<
  typeof selectDepreciationCheckedKiListAction
>;

type RemoveDepreciationCheckedKiListAction = ReturnType<
  typeof removeDepreciationCheckedKiListAction
>;

type TriggerSwapFieldsAction = ReturnType<typeof triggerSwapFieldsAction>;

type SelectDepreciationNameFilterAction = ReturnType<
  typeof selectDepreciationNameFilterAction
>;

type TriggerDepreciationSortAndOrderAction = ReturnType<
  typeof triggerDepreciationSortAndOrderAction
>;

type TriggerDepreciationSortAction = ReturnType<
  typeof triggerDepreciationSortAction
>;

type SelectDepreciationKiTagListAction = ReturnType<
  typeof selectDepreciationKiTagListAction
>;

type SelectDepreciationShowKiTagLabelAction = ReturnType<
  typeof selectDepreciationShowKiTagLabelAction
>;

type DepreciationSettingActions =
  | InitDepreciationSettingAction
  | SearchDepreciationSettingAction
  | RenewDepreciationSettingAction
  | SelectDepreciationFormConditionAction
  | SelectDepreciationTableCheckedAllKiListAction
  | SelectDepreciationCheckedKiListAction
  | RemoveDepreciationCheckedKiListAction
  | TriggerSwapFieldsAction
  | SelectDepreciationNameFilterAction
  | TriggerDepreciationSortAndOrderAction
  | TriggerDepreciationSortAction
  | SelectDepreciationKiTagListAction
  | SelectDepreciationShowKiTagLabelAction;

/* ---------------------------------------------------------------
 * State
 */

type DepreciationSettingState = {
  searchParams: DataDepreciationSearchParams | undefined;
  /**
   * テーブルのチェックボックスで選択されている機種
   */
  checkedKiList: {
    dataType: string;
    ki: string;
  }[];
  /**
   * 機種名フィルタ
   */
  nameFilter: string | undefined;
  /**
   * タグ絞込で選択されているタグリスト
   */
  kiTagList: KiTagListOptions[];
  /**
   * 機種行への機種タグ表示機能のフラグ
   */
  showKiTagLabel: boolean;
};

const initialState: DepreciationSettingState = {
  searchParams: undefined,
  checkedKiList: [],
  nameFilter: undefined,
  kiTagList: [],
  showKiTagLabel: false,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [償却] 設定情報を取得する
 */
function depreciationSettingSelector(rootState: {
  depreciationSettings: DepreciationSettingState;
}) {
  return rootState.depreciationSettings;
}

/**
 * [償却] 機種行への機種タグ表示機能のフラグを取得する
 */
export const depreciationShowKiTagLabelSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.showKiTagLabel
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function depreciationSettingReducer(
  state = initialState,
  action: DepreciationSettingActions
): DepreciationSettingState {
  switch (action.type) {
    case 'SEARCH_DEPRECIATION_SETTING':
      return {
        ...state,
        searchParams: action.payload.searchParams,
      };
    case 'RENEW_DEPRECIATION_SETTING':
      return initialState;
    default:
      return state;
    case 'SELECT_DEPRECIATION_FORM_CONDITION':
      return {
        ...state,
        searchParams: action.payload.formCondition,
      };
    case 'SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST':
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, ...action.payload],
      };
    case 'SELECT_DEPRECIATION_CHECKED_KI_LIST':
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, action.payload],
      };
    case 'REMOVE_DEPRECIATION_CHECKED_KI_LIST':
      return {
        ...state,
        checkedKiList: action.payload,
      };

    case 'SELECT_DEPRECIATION_NAME_FILTER':
      return {
        ...state,
        nameFilter: action.payload.name,
      };
    case 'SELECT_DEPRECIATION_KI_TAG_LIST':
      return {
        ...state,
        kiTagList: action.payload.kiTagList,
      };
    case 'SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL':
      return {
        ...state,
        showKiTagLabel: action.payload.showKiTagLabel,
      };
  }
}
