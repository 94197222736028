import { fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  PlanProgressGraphParams,
  PlanProgressTransitionGraph,
} from '../domain/dataPlanProgressGraph';
import {
  DataPlanProgressTransition,
  DataPlanProgressTransitionParams,
} from '../domain/dataPlanProgressTransition';
import { FavoriteItem } from '../domain/favorites';
import { makePlanProgressDateRangeParams } from '../domain/planProgressTransition/makeDateRange';
import { PlanProgressTransitionFavorite } from '../domain/planProgressTransition/types';
import { LoadingState } from '../domain/schemas';
import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';
import { ShuOption } from '../domain/shu';
import { User } from '../domain/user';

import {
  DataPlanProgressTransitionActionCreators,
  dataPlanProgressTransitionSelector,
  dataPlanProgressTransitionSettingSelector,
} from '../redux/server/dataPlanProgressTransition';
import {
  DataPlanProgressTransitionGraphActionCreators,
  dataPlanProgressTransitionGraphAllSelector,
  dataPlanProgressTransitionGraphSearchConditionSelector,
} from '../redux/server/dataPlanProgressTransitionGraph';
import { SettingsCommentsMonthToDateActionCreators } from '../redux/server/settingsCommentsMonthToDate';
import {
  settingsOptionsPlanProgressTransitionLoadingStateSelector,
  settingsOptionsPlanProgressTransitionsettingsOptionsSelector,
} from '../redux/server/settingsOptionsPlanProgressTransition';
import { ErrorActionCreators } from '../redux/ui/error';
import {
  ChangePlanProgressTransitionReportsCurrentHallsAction,
  ChangePlanProgressTransitionReportsCurrentShuAction,
  ChangePlanProgressTransitionReportsDataRangeAction,
  ChangePlanProgressTransitionReportsFavoriteAction,
  InitPlanProgressTransitionReportsAction,
  PlanProgressTransitionReportsSettingActionCreators,
  PlanProgressTransitionReportsSettingActionTypes,
  PlanProgressTransitionReportsSettingState,
  SaveAsPlanProgressTransitionReportsFavoriteAction,
  SavePlanProgressTransitionReportsFavoriteAction,
  SearchPlanProgressTransitionReportsAction,
  SearchResetPlanProgressTransitionReportsAction,
  defaultShu,
  planProgressReportsGraphShowNumberLabelSelector,
  planProgressReportsGraphShowPlanSelector,
  planProgressReportsGraphShowTotalSelector,
  planProgressTransitionReportsCurrentShuSelector,
  planProgressTransitionReportsSearchConditionSelector,
  planProgressTransitionReportsSelectedFavoriteDataSelector,
  planProgressTransitionReportsSelectedFavoriteSelector,
  planProgressTransitionReportsSelectedFavoriteSettingSelector,
  planProgressTransitionReportsSettingCurrentHallsSelector,
  planProgressTransitionReportsSettingSelector,
} from '../redux/ui/planProgressTransitionReportsSetting';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import { userSelector } from '../redux/ui/user';
import { isValidArea } from '../utils/isValidArea';
import { selectShu2SearchCondition } from '../utils/shu';

/**
 * 初回表示
 */
function* initActionSaga(action: InitPlanProgressTransitionReportsAction) {
  // ユーザ情報取得
  const user: User | undefined = yield select(userSelector);

  const loadingState: LoadingState = yield select(
    settingsOptionsPlanProgressTransitionLoadingStateSelector
  );

  // ローディング中または既にエラーの場合は初回取得しない
  if (loadingState !== 'prepare') {
    return;
  }

  // ユーザがセットされていない場合は、初回取得しない
  if (!user?.userId) return;

  // 現在の検索条件を取得する
  // 他画面からの遷移で選択された状態を保持するため
  const searchConditionParams: DataPlanProgressTransitionParams = yield select(
    planProgressTransitionReportsSearchConditionSelector
  );

  // 店舗選択リストで前回選択されていた店舗
  const prevHalls: string[] = yield select(
    planProgressTransitionReportsSettingCurrentHallsSelector
  );

  // 「担当店舗や日付、当日除外」とそれ以外の検索条件を組み合わせ
  const params = {
    ...action.payload.params,
    ...searchConditionParams,
  };

  // 選択中の種別・種別グループを取得する
  // 他画面からの遷移で選択された状態を保持するため
  const currentShu: ShuOption = yield select(
    planProgressTransitionReportsCurrentShuSelector
  );

  // 担当店舗、およびそれ以外の情報をstoreに登録
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      params
    )
  );

  // 店舗選択リストで前回選択されていた店舗がある場合、店舗を上書きする
  const halls =
    prevHalls.length > 0 ? prevHalls : params.halls ?? [user.hallCodeInCharge];

  // 検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...params,
        halls: halls,
        ...selectShu2SearchCondition(currentShu),
      }
    )
  );

  // 現在選択中のお気に入りデータを取得
  const favorite: PlanProgressTransitionFavorite | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSettingSelector
  );
  // お気に入り反映時にはお気に入りのSagaが動作し、グラフを取得するためお気に入り反映時に実行させないように条件配置
  if (favorite == null) {
    // グラフの検索条件
    const planProgressGraph:
      | PlanProgressTransitionGraph
      | undefined = yield select(dataPlanProgressTransitionGraphAllSelector);
    // 推移グラフデータ取得
    // 予定進捗推移は、初回表示に推移グラフを表示
    yield put(
      DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
        {
          ...params,
          ...selectShu2SearchCondition(currentShu),
          // 店舗選択リストで前回選択されていた店舗がある場合、店舗を上書きする
          halls: halls,
          //初回表示の際fieldがundefined
          ...(planProgressGraph
            ? { field: planProgressGraph.setting.field }
            : {}),
        }
      )
    );
  }

  yield fork(fetchCommentSaga, {
    hallCodes: halls,
    year: params?.year,
    month: params?.month,
  });
}

/**
 * 検索ボタンクリック
 */
function* searchActionSaga(action: SearchPlanProgressTransitionReportsAction) {
  const params = action.payload.params;

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      params
    )
  );

  // 選択中の種別・種別グループを取得する
  const currentShu: ShuOption = yield select(
    planProgressTransitionReportsCurrentShuSelector
  );

  // 検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...params,
        ...selectShu2SearchCondition(currentShu),
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...params,
        ...selectShu2SearchCondition(currentShu),
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: params.halls ?? [],
    year: params?.year,
    month: params?.month,
  });
}

/**
 * 種別変更
 */
function* changeCurrentShuSaga(
  action: ChangePlanProgressTransitionReportsCurrentShuAction
) {
  // レスポンスから現在の検索条件を取得する
  // NOTE: 店舗スライダ導入により、検索フォーム上の選択店舗と実際に検索された店舗が一致しない
  // そのため検索条件を維持したい場合はレスポンスにある店舗を見る必要がある
  const params: DataPlanProgressTransition | undefined = yield select(
    dataPlanProgressTransitionSelector
  );

  //グラフの検索条件
  const planProgressGraph: PlanProgressTransitionGraph = yield select(
    dataPlanProgressTransitionGraphAllSelector
  );

  // 種別を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params ? params.setting : {}),
        ...selectShu2SearchCondition(action.payload.shu),
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params ? params.setting : {}),
        ...selectShu2SearchCondition(action.payload.shu),
        field: planProgressGraph.setting.field,
      }
    )
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangePlanProgressTransitionReportsCurrentHallsAction
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // 現在の検索条件
  const params: DataPlanProgressTransition = yield select(
    dataPlanProgressTransitionSelector
  );

  //グラフの検索条件
  const planProgressGraph: PlanProgressTransitionGraph = yield select(
    dataPlanProgressTransitionGraphAllSelector
  );
  const { halls } = action.payload;
  // 店舗を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params ? params.setting : {}),
        halls,
      }
    )
  );
  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params ? params.setting : {}),
        halls,
        field: planProgressGraph.setting.field,
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: halls,
    year: params?.setting.year,
    month: params?.setting.month,
  });
}
/**
 * 期間スライダーで変更後のデータを取得する
 */
function* changeCurrentDataRangeSaga(
  action: ChangePlanProgressTransitionReportsDataRangeAction
) {
  // 現在の検索条件
  const params: DataPlanProgressTransition = yield select(
    dataPlanProgressTransitionSelector
  );

  //店舗選択リストで選択中の店舗を取得
  const currentHall: string[] = yield select(
    planProgressTransitionReportsSettingCurrentHallsSelector
  );

  const searchConditionParams: DataPlanProgressTransitionParams = yield select(
    planProgressTransitionReportsSearchConditionSelector
  );

  const updatedSearchConditionParams = {
    ...searchConditionParams,
    year: action.payload.year,
    month: action.payload.month,
  };

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      updatedSearchConditionParams
    )
  );

  //グラフの検索条件
  const planProgressGraph: PlanProgressTransitionGraph = yield select(
    dataPlanProgressTransitionGraphAllSelector
  );
  // 期間を変更して検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...(params.setting || {}),
        halls: currentHall,
        year: action.payload.year,
        month: action.payload.month,
      }
    )
  );

  // 推移グラフデータ取得
  yield put(
    DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
      {
        ...(params.setting || {}),
        year: action.payload.year,
        month: action.payload.month,
        field: planProgressGraph.setting.field,
      }
    )
  );

  yield fork(fetchCommentSaga, {
    hallCodes: currentHall,
    year: action.payload.year,
    month: action.payload.month,
  });
}

/**
 * コメントを取得する
 */
function* fetchCommentSaga({
  hallCodes,
  year,
  month,
}: {
  hallCodes: string[];
  year: number | undefined;
  month: number | undefined;
}) {
  // コメントの取得、店舗が1件のみ選択されている場合に表示する
  if (hallCodes.length === 1) {
    yield put(
      SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateAction(
        {
          hallCode: hallCodes[0],
          year: year?.toString() ?? new Date().getFullYear().toString(),
          month: month?.toString() ?? (new Date().getMonth() + 1).toString(),
        }
      )
    );
  } else {
    yield put(
      SettingsCommentsMonthToDateActionCreators.renewSettingsCommentsMonthToDateAction()
    );
  }
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(
  action: SaveAsPlanProgressTransitionReportsFavoriteAction
) {
  // 現在の予定進捗推移の設定すべて
  const pageSetting: PlanProgressTransitionReportsSettingState = yield select(
    planProgressTransitionReportsSettingSelector
  );
  // テーブルの検索条件
  const dataPlanProgressParams:
    | DataPlanProgressTransition['setting']
    | undefined = yield select(dataPlanProgressTransitionSettingSelector);

  // グラフの検索条件
  const graphParams: PlanProgressGraphParams = yield select(
    dataPlanProgressTransitionGraphSearchConditionSelector()
  );

  // グラフのラベル表示条件
  const numberLabel: boolean = yield select(
    planProgressReportsGraphShowNumberLabelSelector
  );
  const planLabel: boolean = yield select(
    planProgressReportsGraphShowPlanSelector
  );
  const totalLabel: boolean = yield select(
    planProgressReportsGraphShowTotalSelector
  );

  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;
  // レスポンスの検索条件がundefinedの場合に動作しない
  if (dataPlanProgressParams === undefined) return;

  // 変更後のお気に入りデータ
  const favorite: FavoriteItem = {
    name: action.payload.name,
    isShared: action.payload.isShared,
    pageName: '予定進捗推移',
    pageSetting: {
      planProgressTransition: {
        searchCondition: pageSetting.searchCondition,
        selectedDateRangeParams: {
          dateRange: pageSetting.selectedDateRangeParams.dateRange,
        },
        currentShu: pageSetting.currentShu,
        currentHalls: pageSetting.currentHalls,
        showNumberLabel: numberLabel,
        showPlan: planLabel,
        showTotal: totalLabel,
        dataPlanProgress: {
          setting: dataPlanProgressParams,
        },
        dataPlanProgressGraph: {
          setting: graphParams,
        },
        isShowRow: pageSetting.isShowRow,
      },
    },
    memo: action.payload.memo,
    privatelySharedUsers: action.payload.sharedUser,
  };

  // お気に入りを新規保存
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([favorite], {})
  );
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(
  action: SavePlanProgressTransitionReportsFavoriteAction
) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteDataSelector
  );

  // 現在の予定進捗推移の設定すべて
  const pageSetting: PlanProgressTransitionReportsSettingState = yield select(
    planProgressTransitionReportsSettingSelector
  );

  // テーブルの検索条件
  const dataPlanProgressParams:
    | DataPlanProgressTransition['setting']
    | undefined = yield select(dataPlanProgressTransitionSettingSelector);

  // グラフの検索条件
  const graphParams: PlanProgressGraphParams = yield select(
    dataPlanProgressTransitionGraphSearchConditionSelector()
  );

  // グラフの表示表示条件
  const numberLabel: boolean = yield select(
    planProgressReportsGraphShowNumberLabelSelector
  );
  const planLabel: boolean = yield select(
    planProgressReportsGraphShowPlanSelector
  );
  const totalLabel: boolean = yield select(
    planProgressReportsGraphShowTotalSelector
  );

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined || selectedFavorite === undefined)
    return;
  // こんなケースはないが念の為
  if (pageSetting.currentShu === undefined) return;
  // レスポンスの検索条件がundefinedの場合に動作しない
  if (dataPlanProgressParams === undefined) return;

  let favorite: FavoriteItem = {};
  if (action.payload.mode === 'memo') {
    // メモのみ更新モードでの変更後のお気に入りデータ
    favorite = {
      ...selectedFavorite,
      memo: action.payload.memo,
    };
  } else {
    // 変更後のお気に入りデータ
    favorite = {
      name: action.payload.name,
      isShared: action.payload.isShared,
      pageName: '予定進捗推移',
      pageSetting: {
        planProgressTransition: {
          searchCondition: pageSetting.searchCondition,
          selectedDateRangeParams: {
            dateRange: pageSetting.selectedDateRangeParams.dateRange,
          },
          currentShu: pageSetting.currentShu,
          currentHalls: pageSetting.currentHalls,
          showNumberLabel: numberLabel,
          showPlan: planLabel,
          showTotal: totalLabel,
          dataPlanProgress: {
            setting: dataPlanProgressParams,
          },
          dataPlanProgressGraph: {
            setting: graphParams,
          },
          isShowRow: pageSetting.isShowRow,
        },
      },
      memo: action.payload.memo,
      privatelySharedUsers: action.payload.sharedUser,
    };
  }

  // お気に入りを上書き
  yield put(
    SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
      selectedFavoriteId,
      favorite
    )
  );
}

/**
 * お気に入り 適用時の処理
 */
function* applyFavoriteByIdSaga(
  action: ChangePlanProgressTransitionReportsFavoriteAction
) {
  // 選択されたお気に入りID
  const selectedFavoriteId = action.payload.favorite;

  // 現在選択中のお気に入りデータを取得
  const favorite: PlanProgressTransitionFavorite | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSettingSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (selectedFavoriteId === undefined) {
    // 担当店舗取得
    const user: User | undefined = yield select(userSelector);
    const {
      searchCondition: halls,
    }: SettingsOptionsPlanProgressTransition = yield select(
      settingsOptionsPlanProgressTransitionsettingsOptionsSelector
    );

    // settingsOptionと担当店舗から担当店舗の情報を取得
    const findHallCodeInCharge = halls.halls.find(
      (hall) => hall.code === user?.hallCodeInCharge
    );

    // 店舗設定
    const favoriteHalls = () => {
      if (favorite?.searchCondition.halls) {
        return favorite?.searchCondition.halls;
      }
      // 担当店舗が設定されている場合は担当店舗を設定
      else if (user?.hallCodeInCharge && findHallCodeInCharge) {
        return [findHallCodeInCharge.code];
      } else {
        const selectedHall = halls.halls.at(0);
        if (!selectedHall) {
          throw new Error('settingsOptionsに該当する店舗が存在しません');
        }
        return [];
      }
    };
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.searchResetPlanProgressTransitionReportsAction(
        favoriteHalls()
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsCurrentHallsAction()
    );
    return;
  }

  if (favorite == null) {
    throw new Error('該当するお気に入りが見つかりません');
  }

  yield fork(applyFavoriteSaga, favorite);
}

/**
 * PlanProgressTransitionFavoriteの内容を実際に反映させる
 */
function* applyFavoriteSaga(favorite: PlanProgressTransitionFavorite) {
  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        'お気に入りの検索条件に存在しないエリアが指定されています。'
      )
    );
  }

  const year = favorite.searchCondition.year;
  const month = favorite.searchCondition.month;
  if (year === undefined || month === undefined) {
    yield put(
      ErrorActionCreators.setError(
        'お気に入りの検索条件に適切な年月が指定されていません。'
      )
    );
    return;
  }

  // 検索期間がカスタム以外の場合は、お気に入り選択時を起点に期間を再算出
  const dateRangeParams = makePlanProgressDateRangeParams(
    favorite.selectedDateRangeParams.dateRange,
    year,
    month
  );

  const currentHalls = favorite.currentHalls;
  if (currentHalls) {
    const hallCodes = currentHalls.at(0);
    if (hallCodes === undefined) {
      yield put(
        ErrorActionCreators.setError(
          'お気に入りの検索条件に適切な店舗が指定されていません。'
        )
      );
      return;
    }

    // コメント適用は単店舗が指定されている場合のみ行うため
    if (currentHalls.length === 1) {
      // コメントの適用
      yield put(
        SettingsCommentsMonthToDateActionCreators.fetchSettingsCommentsMonthToDateAction(
          {
            hallCode: hallCodes,
            year: dateRangeParams.year.toString(),
            month: dateRangeParams.month.toString(),
          }
        )
      );
    }
  }

  // 検索条件をStoreに格納する
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        year: dateRangeParams.year,
        month: dateRangeParams.month,
      }
    )
  );

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsCurrentShuAction(
      favorite.currentShu ?? defaultShu
    )
  );

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.savePlanProgressTransitionReportsDateRangeAction(
      favorite.selectedDateRangeParams.dateRange
    )
  );

  // 検索
  yield put(
    DataPlanProgressTransitionActionCreators.fetchDataPlanProgressTransitionAction(
      {
        ...favorite.searchCondition,
        year: dateRangeParams.year,
        month: dateRangeParams.month,
        halls: favorite.currentHalls,
        ...selectShu2SearchCondition(favorite.currentShu ?? defaultShu),
      }
    )
  );

  // 推移グラフデータの取得
  const dataPlanProgressTransitionGraph =
    favorite.dataPlanProgressGraph?.setting;
  if (dataPlanProgressTransitionGraph !== undefined) {
    yield put(
      DataPlanProgressTransitionGraphActionCreators.fetchDataPlanProgressGraphAction(
        {
          ...dataPlanProgressTransitionGraph,
          year: dateRangeParams.year,
          month: dateRangeParams.month,
        }
      )
    );
    // UIのグラフラベルの表示
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowPlanAction(
        favorite.showPlan
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowNumberLabelAction(
        favorite.showNumberLabel
      )
    );
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanGraphShowTotalAction(
        favorite.showTotal
      )
    );
  }
  // 行の表示管理フラグの反映
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsIsShowRowAction(
      favorite.isShowRow ?? { previousYearSameMonth: true, forecast: true }
    )
  );
}
/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga(
  action: SearchResetPlanProgressTransitionReportsAction
) {
  const favoriteId: number | undefined = yield select(
    planProgressTransitionReportsSelectedFavoriteSelector
  );

  // お気に入り選択時のリセットボタン押下時
  if (favoriteId !== undefined) {
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.changePlanProgressTransitionReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsSearchConditionAction()
  );

  // 種別を初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsCurrentShuAction()
  );

  // 期間タイプを初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsDateRangeAction()
  );

  // 検索条件に店舗を指定する（初期化された検索条件に店舗情報は無く、未指定の場合、全店舗のデータがレスポンスされる）
  if (action.payload.halls !== undefined) {
    yield put(
      PlanProgressTransitionReportsSettingActionCreators.selectPlanProgressTransitionReportsCurrentHallsAction(
        action.payload.halls
      )
    );
  }

  const planProgressSetting: PlanProgressTransitionReportsSettingState = yield select(
    planProgressTransitionReportsSettingSelector
  );

  yield put(
    PlanProgressTransitionReportsSettingActionCreators.searchPlanProgressTransitionReportsAction(
      {
        ...planProgressSetting.searchCondition,
        halls: planProgressSetting.currentHalls,
      }
    )
  );
  // 行の表示管理フラグの初期化
  yield put(
    PlanProgressTransitionReportsSettingActionCreators.resetPlanProgressTransitionReportsIsShowRowAction()
  );
}

/**
 * 初回表示時のデータを取得する
 */
function* handleInitSearchSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.INIT_PLAN_PROGRESS_TRANSITION_REPORTS,
    initActionSaga
  );
}

// 検索ボタンクリック時にデータを取得する
function* handleSearchSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SEARCH_PLAN_PROGRESS_TRANSITION_REPORTS,
    searchActionSaga
  );
}

/**
 * 種別変更時にデータを取得する
 */
function* handleChangeCurrentShuSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_SHU,
    changeCurrentShuSaga
  );
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 選択中の期間変更時、データを取得する
 */
function* handleChangeCurrentDataRangeSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_DATA_RANGE,
    changeCurrentDataRangeSaga
  );
}

/**
 * リセットボタン押下時予定進捗推移の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SEARCH_RESET_PLAN_PROGRESS_TRANSITION_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * お気に入り関連の処理
 */
function* handleFavoriteSaga() {
  // お気に入り適用
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.CHANGE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // // お気に入り上書き保存
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SAVE_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    PlanProgressTransitionReportsSettingActionTypes.SAVE_AS_PLAN_PROGRESS_TRANSITION_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

/**
 * 予定進捗推移の検索フォームに関するタスクを実行する
 */
export function* planProgressTransitionReportsSettingSagas() {
  yield fork(handleInitSearchSaga);
  yield fork(handleSearchSaga);
  yield fork(handleChangeCurrentShuSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleChangeCurrentDataRangeSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleResetSaga);
}
